import z from 'zod';
import { Prettify } from '../../utils/pretify';

export namespace Notification {

  export const searchRequestSchema = z.object({
    page: z.number().default(1).optional(),
    perPage: z.number().default(10).optional(),
    dir: z.enum(['asc', 'desc']).default('desc').optional(),
    orderBy: z.enum(['readAt', 'sentAt', 'id']).default('id').optional(),
  });

  export type SearchRequest = z.infer<typeof searchRequestSchema>;

  export interface NotificationItem {
    content: string;
    description: string;
    id: number;
    read: boolean;
    readAt: null | string;
    sentAt: string;
    title: string;
  }

  export type NotificationsResponse = Prettify<{
    data: NotificationItem[]
    error: false,
    orderBy: {
      id: 'asc' | 'desc'
    },
    page: number;
    perPage: number;
    total: number;
  }>;

  export type NotificationDetails = Prettify<{
    data: NotificationItem
  }>;

  export interface RefreshTokenRequest {
    token: string
  }
}
