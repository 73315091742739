import date from './date';

/**
 * Converte uma data no formato brasileiro (DD/MM/AAAA) para o formato internacional (AAAA-MM-DD)
 *
 * @param {string} date Data no formato brasileiro (DD/MM/AAAA)
 * @returns {string} Data no formato internacional (AAAA-MM-DD)
 */
function convertBRDateToInternational(brDate: string): string {
  if (date(brDate, 'DD/MM/YYYY').isValid()) {
    const [day, month, year] = brDate.split('/').map((v) => (v.length === 1 ? `0${v}` : v));

    const out = `${year}-${month}-${day}`;
    if (!date(out).isValid()) throw new Error('Data inválida');

    return `${year}-${month}-${day}`;
  }

  if (date(brDate, 'YYYY-MM-DD').isValid()) return brDate;
  throw new Error('Data inválida');
}

const cleanNumber = (str: string): string => str.replace(/\D/g, '');

export default {
  cleanNumber,
  convertBRDateToInternational,
};
