import { AxiosError } from 'axios';
import SdkError from '../../../errors/SdkError';
import { HttpInstance } from '../../../types';
import { Address } from './address.types';

export default (httpClient: HttpInstance) => ({
  /**
   * Cadastra um novo endereço.
   * @param args - Os argumentos necessários para cadastrar o endereço.
   * @returns Uma promessa que resolve com os dados do endereço cadastrado.
   * @throws {SdkError} - Se houver um erro de validação ou HTTP.
   */
  create: async (args: Address.CreateRequest) => {
    const body = await Address.addressRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.post<Address.CreateAddressResponse>('/v2/address', body).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
   * Edita um endereço existente.
   * @param id - O ID do endereço a ser editado.
   * @param args - Os argumentos necessários para editar o endereço.
   * @returns Uma promessa que resolve com os dados do endereço editado.
   * @throws {SdkError} - Se houver um erro de validação ou HTTP.
   */
  update: async (id: number, args: Address.UpdateRequest) => {
    const body = await Address.addressRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.put<Address.UpdateAddressResponse>(`/v2/address/${id}`, body).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

});
