import { z } from 'zod';

export namespace Payment {
  /**
   * Interface representando o parâmetro opcional antes de pegar os métodos de pagamento
   */

  export interface PaymentMethodRequest {
    storeId: number
  }

  /**
   * Interface representando a resposta ao pega um método de pagamento
   */

  export interface PaymentMethodOneResponse {
    id: string
    description: string
    item_type: string
    customer_id: string
    data: Data
  }

  export interface Data {
    brand: string
    holder_name: string
    display_number: string
    bin: string
    year: number
    month: number
    last_digits: string
    first_digits: string
    masked_number: string
  }

  /**
   * Interface representando a resposta ao pegar os métodos de pagamento
  */

  export type ItemType = 'credit_card' | 'pix';

  export interface PaymentMethod {
    id: string;
    item_type: ItemType;
    customer_id: string;
    description: string;
    data: {
      bin: string;
      brand: string;
      display_number: string;
      first_digits: string;
      holder_name: string;
      last_digits: string;
      masked_number: string;
      month: number;
      year: number;
    };
    debitData?: {
      cardNumber: string;
      cardHolderName: string;
      cardExpiration: string;
      cardCvv: string;
      holderCpf: string;
    }
  }

  export interface PaymentMethodResponse {
    isDefaultPaymentId: string;
    payments: PaymentMethod[];
  }

  export const addPaymentMethod = z.object({
    userId: z.string(),
    number: z.string(),
    verificationValue: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    month: z.string(),
    year: z.string(),
    cpf: z.string(),
    description: z.string(),
  });

  export const editPaymentMethodSchema = addPaymentMethod.extend({
    customerId: z.string(),
    paymentMethodId: z.string(),
  });

  export type AddPaymentMethodRequest = z.infer<typeof addPaymentMethod>;
  export type EditPaymentMethodRequest = z.infer<typeof editPaymentMethodSchema>;

  /**
   * Interface representando o parâmetro para definir como padrão o método de pagamento
   */

  /*
   * Purchase
  */

  export const PurchaseRequestSchema = z.object({
    couponDiscount: z.number().optional(),
    couponName: z.string().optional(),
    currentCpf: z.string(),
    items: z.array(z.object({
      listPrice: z.number(),
      planogramId: z.number(),
      price: z.number(),
      productId: z.number(),
      productName: z.string(),
      quantity: z.number(),
    })),
    paymentMethod: z.enum(['pix', 'credit_card', 'plugpag_credit', 'plugpag_debit', 'plugpag_voucher']),
    paymentMethodId: z.string().nullable(),
    status: z.enum(['pending']),
    storeId: z.string().optional(),
    pwaId: z.string().optional(),
    pwaPush: z.string().optional(),
  });

  export interface PurchaseResponse {
    id: number;
    invoiceId: string | null;
    pix: string;
    saleId: number;
    url: string;
    expires_at: string
    paymentMethod: string
    totalPrice: number
  }

  /**
   * Esquema que representa os métodos de pagamentos habilitado para uma loja especifica
  */
  export interface EnablePaymentsMethod {
    is_active: number;
    is_gateway_active: number;
    is_global_active: number;
    name: string;
    id: number;
    store_id: number;
  }

  export interface EnablePaymentMethodResponse {
    payments: EnablePaymentsMethod[]
  }

  export type CreatePurchase = z.infer<typeof PurchaseRequestSchema>;

  export interface DefaultPaymentMethodRequest {
    default_payment_method_id: string
  }

  /**
     * Interface representado a resposta ao definir como padrão um método de pagamento
   */

  export interface DefaultPaymentMethodResponse {
    status: string
  }

}
