import z from 'zod';
import { Prettify } from '../../utils/pretify';

export namespace Store {
  /**
   * Representa uma loja
   */
  export interface Store {
    logo: string;
    id: number;
    is_open: number;
    name: string;
    address_id: number;
    shopmanager_id: number;
    distance: number;
    latitude: number;
    longitude: number;
    address: Address;
    shopManager: ShopManager;
  }

  export interface ShopManager {
    name: string
    user_id: number
    emit_invoice: number
    id: number
  }

  export interface Address {
    street: string;
    district: string;
    id: number;
  }

  // existe outra interface Store que é usada no método get
  export interface Store2 {
    id: number
    shopmanager_id: number
    address_id: number
    name: string
    category: string
    logo: string
    latitude: number
    longitude: number
    is_open: number
    description: any
    type: string | undefined;
    phone: string | undefined;
    inventory: string | undefined;
    qr_code: string | undefined;
    active: number
    is_qr_code_required: number
    fraud_prevention_id: number
    store_business_type: string | undefined;
    store_business_quantity: string | null;
    hide_zero_stock: number
    active_pwa: number
    active_app: number
    allow_purchases_stock_less_than_quantity: number
    automatic_refund: number
    automatic_refund_value: number
    address: Address
    shopManager: ShopManager
  }

  export interface Meta {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: any
  }

  export const searchRequestSchema = z.object({
    latitude: z.number(),
    longitude: z.number(),
    name: z.string().optional(),
    distance: z.number().optional().default(999999),
    order: z.enum(['distance', 'name']).default('distance'),
    page: z.number().optional().default(1),
    perPage: z.number().optional().default(10),
    type: z.enum(['app', 'pwa', 'totem']).optional().default('app'),
    favoriteList: z.array(z.string()).optional().default([]),
  });

  export type SearchRequest = Partial<z.infer<typeof searchRequestSchema>>;

  export type SearchResponse = Prettify<{
    data: Store[];
    meta: Meta;
  }>;

  /**
   * Consulta uma loja pelo ID
   */

  export type GetRequest = Prettify<number>;
  export type GetResponse = Prettify<Store2>;

}
