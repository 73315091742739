import { z } from 'zod';

export namespace Feedback {

  export const feedbackType = z.enum([
    'problem_store',
    'problem_sale',
    'problem_app',
    'suggestion_store',
    'support',
    'other'], { required_error: 'Por favor, informe o tipo de solicitação' });

  export const feedbackSubtype = z.enum([
    'product_without_registration',
    'missing_product',
    'expired_product',
    'product_withdrawal',
    'spoiled_product',
    'product_out_of_stock',
    'problem_with_doors',
    'defective_equipment',
    'other'], { required_error: 'Por favor, informe o tipo de problema' });

  export const type = z.enum([
    'suggestion',
    'complaint',
    'reversal',
    'informative',
    'others']);

  /**
   * Esquema para enviar feedback do cliente.
   */
  export const feedbackRequestSchema = z.object({
    storeId: z.number().min(0),
    feedbackType,
    type,
    feedbackSubtype,
    description: z.string({ required_error: 'Por favor, informe uma descrição' }).min(3, 'Por favor, informe no mínimo 3 caracteres').max(1024),
  });

  /**
   * Interface para a resposta ao criar um feedback
   */
  export interface ResponseCreateFeedBack extends AddHelpSchema {
    id: number;
    userId: number;
    salesId: number | null;
    createdAt: string;
    updatedAt: string | null;
  }

  /**
   * Tipo para a requisição de feedback.
   */
  export type AddHelpSchema = z.infer<typeof feedbackRequestSchema>;

}
