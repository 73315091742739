import { AxiosError } from 'axios';
import SdkError from '../../errors/SdkError';
import { HttpInstance } from '../../types';
import { Orders } from './orders.types';

/**
 * Modulo de compras da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Busca as compras do usuário
   *
   * @param {Orders.SearchRequest} args Argumentos da busca
   */
  search: async (args: Orders.SearchRequest) => {
    const params = await Orders.searchRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.get<Orders.SearchResponse>('/sales/history/paid-pendent', { params }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
  /**
   * Consulta uma compra pelo ID
   * @param {number} id ID da compra
   */
  get: async (id: Orders.GetRequest) => {
    const { data } = await httpClient.get<Orders.GetResponse>(`/sales/history/show?salesId=${id}`);
    return data;
  },

  /**
   * Solicita o cancelamento da compra
   * @param {Orders.SaleCancelRequest} args Corpo da requisição
   */
  cancel: async (args: Orders.SaleCancelRequest) => {
    const body = await Orders.cancelRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.post<Orders.SaleCancelResponse>('/sales/request/cancel', body).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /*
   * Chama a api para obter o valor total do desconto dos produtos com base nos parâmetros enviados
  */
  getCouponDiscount: async (args: Orders.ParametersToGetDiscountCoupon) => {
    const params = await Orders.GetDiscountCoupon.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.post<Orders.CheckDiscountResponse>('/coupons/v2/check-coupon', params).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /*
  * Busca venda a partir de um totem e loja especifica
  */
  getSalesByTotem: async (args: Orders.GetSalesByPWARequest) => {
    const params = await Orders.GetSalesByPWASchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.get<Orders.OrdersResponse>('/sales/history/pwa', { params }).catch((err) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
  /**
   * Envia Recibo para o email
  */
  sendReceipt: async (args: Orders.SendReceiptRequest) => {
    const params = await Orders.SendReceiptSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.post<Orders.SendReceiptResponse>('/sales/send-confirmation', params).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
});
