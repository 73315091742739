import { AxiosError } from 'axios';
import SdkError from '../../../errors/SdkError';
import { HttpInstance } from '../../../types';
import { Feedback } from './feedback.types';

export default (httpClient: HttpInstance) => ({
  /**
   * Envia feedback do cliente.
   * @param args - Os argumentos necessários para enviar o feedback.
   * @returns Uma promessa que resolve com os dados do feedback enviado.
   * @throws {SdkError} - Se houver um erro de validação ou HTTP.
   */
  create: async (args: Feedback.AddHelpSchema) => {
    const body = await Feedback.feedbackRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.post<Feedback.ResponseCreateFeedBack>('/client-feedback', body).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
});
