import z from 'zod';
import { Prettify } from '../../utils/pretify';

export namespace Product {

  export interface Meta {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: any
  }

  const baseRequestSchema = z.object({
    storeId: z.number(),
    page: z.number().optional().default(1),
    perPage: z.number().optional().default(10),
  });

  export const productRequestSchema = z.object({
    storeId: z.number(),
    productId: z.number(),
  });

  export const searchProductByBarcodeSchema = z.object({
    storeId: z.number(),
    barCode: z.number(),
  });

  export const categoryRequestSchema = baseRequestSchema.extend({
    categoryId: z.number(),
  });

  export const productSearchRequestSchema = z.object({
    storeId: z.number(),
    productName: z.string(),
    page: z.number().optional().default(1),
    perPage: z.number().optional().default(20),
  });

  export const soldoutRequestSchema = baseRequestSchema;
  export const buyagainRequestSchema = baseRequestSchema;

  export type CategoryRequest = z.infer<typeof categoryRequestSchema>;
  export type SoldoutRequest = z.infer<typeof soldoutRequestSchema>;
  export type BuyagainRequest = z.infer<typeof buyagainRequestSchema>;
  export type ProductSearchRequest = z.infer<typeof productSearchRequestSchema>;

  export interface BarCode {
    id: number;
    product_id: number;
    code: string;
  }

  export interface Category {
    id: number
    shopmanager_id: number
    name: string
    is_for_legal_age: number
    created_at: string
    updated_at: string
    expected_gross_margin: number | null
    expected_store_stock: number | null
  }

  export interface Product {
    id: number;
    name: string;
    Category: Category;
    category_id: number;
    provider_id: number;
    description: string;
    image_url: string;
    last_price: number;
    fiscal: number;
    ncm: number;
    cest: number;
    package_multiplier: number;
    created_at: string;
    updated_at: string;
    BarCodes: BarCode[];
  }

  export type Favorite = {
    id: number;
    client_id: number;
    planogram_id: number;
    created_at: string;
    updated_at: string;
  };

  export interface Planogram {
    id: number;
    planogram_code: string;
    status: string;
    standard_amount: number;
    alert_level: number;
    price_list_in_cents: string;
    price_final_in_cents: string;
    stock_amount: number;
    created_at: string;
    updated_at: string;
    product_id: number;
    store_id: number;
    favorites: Favorite[];
    product: Product;
  }

  /**
   * Tipagem na busca de produto por código de barras
   */

  export interface ProductByBarcodeResponse {
    id: number
    planogram_code: string
    status: string
    standard_amount: number
    alert_level: number
    price_list_in_cents: number
    price_final_in_cents: number
    stock_amount: number
    created_at: string
    updated_at: string
    due_date: string | null
    product_id: number
    store_id: number
    favorites: any[]
    product: ProductItemByBarcode
  }

  export interface ProductItemByBarcode {
    id: number
    name: string
    category_id: number
    provider_id: number
    description: string
    image_url: string
    last_price_in_cents: string
    fiscal: string
    ncm: string
    cest: string
    package_multiplier: number
    created_at: string
    updated_at: string
    shopmanager_id: number
    cfop: number
    icms_tax_situation: number
    icms_aliquot: number
    icms_calculation_basis: number
    icms_calculation_basis_mode: number
    pis_cst: number
    pis_aliquot: number
    cofins_cst: number
    cofins_aliquot: number
    tax_benefit_code: number
    imported_expected_from_category: number
    expected_gross_margin: string
    expected_store_stock: string
    Category: CategoryProductItemByBarcode
  }

  export interface CategoryProductItemByBarcode {
    id: number
    shopmanager_id: number
    name: string
    is_for_legal_age: number
    created_at: string
    updated_at: string
    expected_gross_margin: number
    expected_store_stock: number
  }

  /**
 * Tipagem para produtos das rotas V2
 */
  export interface PlanogramItem {
    price_list_in_cents: number;
    price_final_in_cents: number;
    stock_amount: number;
    product_id: number;
    store_id: number;
    id: number;
    status: string;
    alert_level: number;
    product: ProductItem;
  }

  export interface ProductItem {
    id: number;
    name: string;
    image_url: string;
    category_id: number;
    BarCodes: BarCode[]
    Category: Category
  }

  export type ProductResponse = {
    data: PlanogramItem[];
    meta: Meta;
  };
  /**
 * ---------------Finish---------------------
 */

  export type SearchResponse = Prettify<{
    data: Planogram[];
    meta: Meta;
  }>;

  export type GetRequest = {
    productId: number;
    storeId: number;
  };

  export type ParamsSearchByBarcode = {
    barCode: number;
    storeId: number;
  };

  export type GetProductResponse = Prettify<Planogram>;

  /**
 * Resposta de produtos por categoria
 */
  export type CategoryResponse = Prettify<ProductResponse>;

  /**
 * Resposta de produtos com ofertas.
 */
  export type SoldoutResponse = Prettify<ProductResponse>;

  /**
 * Resposta de produtos do compre de novo
 */
  export type BuyAgainResponse = Prettify<ProductResponse>;
}
