import { AxiosError } from 'axios';
import SdkError from '../../../errors/SdkError';
import { HttpInstance } from '../../../types';
import { Favorite } from './favorite.types';

/**
 * Modulo de favoritos da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Buscar lista de planogramas favoritados
   */
  get: async () => {
    const { data } = await httpClient.get<Favorite.GetFavoriteResponse>('/app/v2/favorites').catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /**
   * Favoritar um planogram
   *
   * @param {number} planogramId ID do planogram
   */
  favorite: async (planogramId: number) => {
    const { data } = await httpClient.post<Favorite.FavoriteResponse>(`/profile/favorite/${planogramId}`).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
   * Desfavoritar um planogram
   *
   * @param {number} planogramId ID do planogram
   */
  unfavorite: async (planogramId: number) => {
    const { data } = await httpClient.post<Favorite.UnfavoriteResponse>(`/profile/unfavorite/${planogramId}`).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
});
