import z from 'zod';
import string from '../../utils/string';
import date from '../../utils/date';
import { Prettify } from '../../utils/pretify';

export namespace Customer {
  /**
   * Esquema para atualizar dados da conta do usuário.
   */
  export const updateRequestSchema = z.object({
    dateBirth: z.string()
      .min(1, { message: 'Data de nascimento obrigatória' })
      .transform(string.convertBRDateToInternational)
      .superRefine((value, ctx) => {
        if (!date(value, 'YYYY-MM-DD', true).isValid()) {
          return ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Data de nascimento inválida',
          });
        }
        if (!date(value).isBefore(date().subtract(18, 'years'))) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Você precisa ser maior de idade para continuar',
          });
        }
        if (date(value).isBefore(date().subtract(100, 'years'))) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Você precisa ser mais jovem para continuar :P',
          });
        }

        return z.OK;
      }),
    name: z.string().superRefine((value, ctx) => {
      if (value.length < 1) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Nome Obrigatório',
        });
      }

      if (value.length < 3) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Nome muito curto',
        });
      }

      return z.OK;
    }),
    phone: z.string()
      .min(1, 'Telefone obrigatório')
      .transform(string.cleanNumber)
      .refine((value) => [11, 12].includes(value.length), 'Telefone inválido'),
  });

  /**
 * Interface representando um endereço.
 */
  export interface Address {
    addresses: Adress[]
    id: number
    user_id: number
    name: string
    cpf: string
    date_birth: string
    phone: string
    profile_image: string
    user: User,
    tokenExpiresAt: string
  }

  export interface Adress {
    id: number
    client_id: number
    address_id: number
    active: number
    nickname: string
    deleted_at: any
    created_at: string
    updated_at: string
    address: {
      id: number
      street: string
      state: string
      district: string
      zipcode: string
      country: string
      city: string
      number: string
      complement: string
      created_at: string
      updated_at: string
    },
  }

  /**
 * Interface representando um usuário.
 */
  export interface User {
    id: number
    email: string
    is_default_payment_id: string
  }

  /**
 * Interface representando a resposta ao enviar uma imagem.
 */
  export interface UploadImageResponse {
    bucket: string;
    created_at: string;
    created_by_user_id: number;
    id: string;
    is_public: boolean;
    mime_type: string;
    name: string;
    path: string;
    size: number;
    source: string;
    storage: string;
    updated_at: string;
    url: string;
  }

  /**
 * Classe para esquema de requisição de imagem.
 */
  export declare class RequestImageSchema {
    append(name: string, value: any): void;
    getAll(): Array<FormDataValue>;
    getParts(): Array<FormDataPart>;
  }

  export type FormDataPart =
    | {
      string: string;
      headers: { [name: string]: string };
    }
    | {
      uri: string;
      headers: { [name: string]: string };
      name?: string | undefined;
      type?: string | undefined;
    };

  export type FormDataValue =
    | string
    | { name?: string | undefined; type?: string | undefined; uri: string };

  /**
 * Tipo dos dados a serem atualizados na conta do usuário.
 */
  export type UpdateRequestSchema = Prettify<z.infer<typeof updateRequestSchema>>;

  /**
 * Resposta ao obter informações do cliente.
 */
  export type GetCustomerResponse = Prettify<Address>;

  /**
 * Resposta ao atualizar dados de um cliente.
 * Este tipo é usado como retorno ao alterar dados de um cliente.
 */
  export type UpdateCustomerResponse = Prettify<Address>;

  /**
 * Resposta ao excluir um cliente.
 * Este tipo indica que a operação de exclusão foi bem-sucedida.
 */
  export type DeleteCustomerResponse = Prettify<void>;

}
