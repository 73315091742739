import { AxiosError } from 'axios';
import SdkError from '../../../errors/SdkError';
import { HttpInstance } from '../../../types';
import { Payment } from './payment.types';

export default (httpClient: HttpInstance) => ({
  /**
   * Consulta os métodos de pagamento do usuário.
   * @param params - Objeto onde contém como param o ID da loja (opcional)
   * @returns Uma promessa que resolve com os dados de métodos de pagamentos do usuário
   * @throws {SdkError} - Se houver um erro HTTP.
   */

  get: async (params?: Payment.PaymentMethodRequest) => {
    const { data } = await httpClient.get<Payment.PaymentMethodResponse>('/payment-methods-store', {
      params,
    }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /**
   * Consulta um método de pagamento do usuário.
   * @param id - ID do método de pagamento
   * @returns Uma promessa que resolve com os dados do método de pagamentos do usuário
   * @throws {SdkError} - Se houver um erro HTTP.
   */

  getOne: async (id: string) => {
    const { data } = await httpClient.get<Payment.PaymentMethodOneResponse>(`/payment-method/${id}`).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /*
  /* Adiconar um método de pagamento
  */
  addPaymentMethod: async (args: Payment.AddPaymentMethodRequest) => {
    const params = await Payment.addPaymentMethod.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.post<Payment.PaymentMethodResponse>('/payment-methods', params).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /*
  /* Edita um método de pagamento
  */
  put: async (args: Payment.EditPaymentMethodRequest) => {
    const params = await Payment.editPaymentMethodSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.patch<Payment.PaymentMethodOneResponse>('/payment-methods', params).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /**
   * Define como padrão um método de pagamento pro usuário.
   * @param params - parâmetro a ser passado na requisição
   * @returns Uma promessa que resolve com o status da requisição
   * @throws {SdkError} - Se houver um erro HTTP.
   */

  default: async (params: Payment.DefaultPaymentMethodRequest) => {
    const { data } = await httpClient.put<Payment.DefaultPaymentMethodResponse>('/payment-methods', {}, {
      params,
    }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /*
   * Efetua uma compra
  */
  purchase: async (params: Payment.CreatePurchase) => {
    const body = await Payment.PurchaseRequestSchema.parseAsync(params).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.post<Payment.PurchaseResponse>('/sales', body).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
  /*
   * Pega todos os métodos de pagamento habilitado para um loja
  */
  getEnabledPaymentMethodsByStore: async (storeId: number) => {
    const { data } = await httpClient.get<Payment.EnablePaymentMethodResponse>(`/store/${storeId}/payment-methods`).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
});
