import { AxiosError } from 'axios';
import SdkError from '../../../errors/SdkError';
import { HttpInstance } from '../../../types';
import { Category } from './category.types';

/**
 * Modulo de categorias da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Buscar categorias de uma loja
   */
  get: async (params: { storeId: number, perPage: number, page: number }) => {
    const { storeId, perPage, page } = params;
    const { data } = await httpClient.get<Category.CategoryResponse>(
      `/app/v2/categories/store/${storeId}?page=${page}&perPage=${perPage}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
});
