const getFirstNineDigits = (cpf: string) => cpf
  .slice(0, 9)
  .split('')
  .map((item: string) => Number(item));

const getLastNineDigitsBeforeSecondDigitChecker = (cpf: string) => cpf
  .slice(1, 10)
  .split('')
  .map((item: string) => Number(item));

const handleRestOfSum = (rest: number) => (rest < 2 ? 0 : 11 - rest);

const sumOf = (numArray: number[]) => numArray.reduce(
  (acc: number, number: number, index: number) => acc + number * (10 - index),
  0,
);

const getFirstDigitChecker = (cpf: string) => handleRestOfSum(sumOf(getFirstNineDigits(cpf)) % 11);

const getSecondDigitChecker = (
  cpf: string,
) => handleRestOfSum(sumOf(getLastNineDigitsBeforeSecondDigitChecker(cpf)) % 11);

export const isValidCPF = (cpf: string) => {
  let cpfValidation = cpf;
  cpfValidation = cpfValidation.toString();
  cpfValidation = cpfValidation.replace(/[^\d]+/g, '');
  if (cpfValidation.match(/(\d)\1{10}/)) return false;

  return (
    cpfValidation.slice(-2)
    === `${getFirstDigitChecker(cpfValidation)}${getSecondDigitChecker(cpfValidation)}`
  );
};

export default {
  isValid: isValidCPF,
};
