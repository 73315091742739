import { AxiosError } from 'axios';
import SdkError from '../../../errors/SdkError';
import { HttpInstance } from '../../../types';
import { Lock } from './lock.types';

/**
 * Modulo de Lock da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Buscar locks de uma loja
   */
  get: async (params: { storeId: number, perPage: number, page: number }) => {
    const { storeId, perPage, page } = params;
    const { data } = await httpClient.get<Lock.LockResponse>(
      `/lock/all?storeId=${storeId}?page=${page}&perPage=${perPage}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
  /**
   * Abrir uma ou todas trava(s) em uma loja
   */
  open: async (params: { storeId: number, lockId?: number | null }) => {
    const { storeId } = params;
    const { data } = await httpClient.get<Lock.LockOpenResponse>(
      `/store/${storeId}/lock${params?.lockId ? `/${params?.lockId}` : ''}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
});
