import axios, { AxiosError } from 'axios';
import { HttpInstance } from '../../types';
import { Session } from './session.types';
import { SdkError } from '../../errors';

export default (httpClient: HttpInstance) => ({
  /**
   * Retorna o status da sessão do usuário
   * @returns string
   */
  login: async (params: Session.SigninRequest, notificationToken = '') => {
    const data = await Session.signinSchema.parseAsync(params);
    const response = await httpClient.post<Session.SigninResponse>('/sessions/login', {
      ...data,
      notificationToken,
    });
    return response;
  },
  /**
   * Efetua o login com o Facebook e retorna o status da sessão do usuário
   * @param token Access token do Facebook
   */
  loginWithFacebook: async (token: string, notificationToken = '') => {
    const { data: { id, email } } = await axios.get<any>(`https://graph.facebook.com/me?fields=email,id&access_token=${token}`);

    const response = await httpClient.post<Session.SigninWithSocialResponse>('/sessions/social-login', {
      socialToken: token,
      email,
      providerId: id,
      provider: 'facebook',
      notificationToken,
    });
    return response;
  },
  /**
   * Efetua o login com a Apple e retorna o status da sessão do usuário
   * @param identityToken Identity token da Apple
   * @param notificationToken  Token de notificação do usuário
   * @example
   * await sdk.sessions.loginWithApple(appleIdentityToken, 'optionalNotificationToken');
   */
  loginWithApple: async (identityToken: string, user: { email: string, id: string }, notificationToken = '') => {
    const response = await httpClient.post<Session.SigninWithSocialResponse>('/sessions/social-login', {
      email: user.email,
      socialToken: identityToken,
      notificationToken,
      providerId: user.id,
      provider: 'apple',
    });
    return response;
  },
  /**
   * Efeua o login com o Google e retorna o status da sessão do usuário
   *
   * @param idToken  Identity token do Google
   * @param notificationToken  Token de notificação do usuário
   */
  loginWithGoogle: async (idToken: string, user: { email: string, id: string }, notificationToken = '') => {
    const response = await httpClient.post<Session.SigninWithSocialResponse>('/sessions/social-login', {
      email: user.email,
      socialToken: idToken,
      notificationToken,
      providerId: user.id,
      provider: 'google',
    });
    return response;
  },
  /**
   * Re-enviar email de confirmação de conta
   */
  resendConfirmation: async ({ email }: Session.ResendConfirmationRequest) => {
    const response = await httpClient.post<Session.ResendConfirmationResponse>('/sessions/resend-confirm-account', { email }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return response;
  },
  /**
  /**
   * Retorna o status da sessão do usuário
   */
  status: async () => {
    const response = await httpClient.get<Session.StatusResponse>('/sessions/get-user');
    return response;
  },
  /**
   * Efetua o logout do usuário
   * @example
   * await sdk.sessions.logout();
   * @returns void
   */
  logout: async () => {
    const response = await httpClient.get<Session.LogoutResponse>('/sessions/logout');
    return response;
  },
  /**
   * Recupera a senha do usuário
   * @param email Email do usuário
   */
  recoverPassword: async (email: string) => {
    const data = await Session.recoverPasswordSchema.parseAsync({ email });
    const response = await httpClient.post<Session.RecoverPasswordResponse>('/sessions/forgot-password', data);
    return response;
  },
  /**
   * Atualiza a senha do usuário
   * @param password Nova senha
   * @param token Token de recuperação
   * @throws {ZodError} Se a senha não for válida
   */
  register: async (params: Session.RegisterRequest) => {
    const data = await Session.registerSchema.parseAsync(params);
    const response = await httpClient.post<Session.RegisterResponse>('/users/client', data);
    return response;
  },
});
