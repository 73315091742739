import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const date = dayjs;

date.extend(utc);
date.extend(timezone);
date.extend(advancedFormat);
date.extend(relativeTime);
date.extend(localizedFormat);
date.extend(customParseFormat);

date.locale('pt-br');
date.tz.setDefault('America/Sao_Paulo');

export default date;
