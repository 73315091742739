import z from 'zod';
import { Prettify } from '../../utils/pretify';

export namespace Orders {

  /**
   * Schema para busca de compras
   */
  export const searchRequestSchema = z.object({
    page: z.number().optional().default(1),
  });

  export type SearchRequest = z.infer<typeof searchRequestSchema>;

  export interface Meta {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: any
  }

  export type InvoiceStatus =
    | 'pending'
    | 'paid'
    | 'canceled'
    | 'in_analysis'
    | 'draft'
    | 'partially_paid'
    | 'refunded'
    | 'expired'
    | 'in_protest'
    | 'chargeback'
    | 'paid_under_validation';
  // test
  export interface Store {
    id: number;
    shopmanager_id: number;
    address_id: number;
    name: string;
    category: string;
    logo: string;
    latitude: number;
    qr_code: string | null;
    longitude: number;
    is_open: number;
    description: string | null;
    type: string;
    phone: string;
    inventory: string;
    created_at: string;
    updated_at: string;
    is_qr_code_required: boolean;
    address: {
      id: number;
      street: string;
      state: string;
      district: string;
      zipcode: string;
      country: string;
      city: string;
      number?: string;
      complement?: string;
      created_at: string;
      updated_at: string;
    };
  }

  export interface OrderInfo {
    id: number;
    client_id: number;
    status: InvoiceStatus;
    created_at: string;
    updated_at: string;
    total_price: number;
    payment_method: 'credit_card' | 'pix';
    store_id: number;
    store: Store;
    card_mask: string;
    salesItems: Array<SalesItems>
  }

  export type SalesItems = {
    quantity: number,
    sales_id: number
  };

  export type SearchResponse = {
    data: OrderInfo[];
    meta: Meta;
  };

  export interface SaleItems {
    id: number;
    salesId: number;
    planogramId: number;
    price: number;
    listPrice: number;
    quantity: number;
    createdAt: string;
    updatedAt: string;
    productName: string;
    name: string;
    imageUrl: string;
  }

  export interface OrdersDetails {
    id: number;
    clientId: number;
    status: InvoiceStatus;
    couponDiscount?: number;
    invoiceId: string;
    cardMask: string;
    createdAt: string;
    updatedAt: string;
    totalPrice: number;
    paymentMethod: string;
    storeId: number;
    total: number;
    taxCoupon: string | null;
    qrcodeUrl: string | null;
    danfe: string | null;
    saleItems: SaleItems[];
    infos: {
      store: {
        name: string;
        logo: string;
      }
    }
  }

  /*
   *Schema para solicitação de cancelamento de compra
  */

  export const cancelRequestSchema = z.object({
    saleId: z.number(),
    reason: z.enum([
      'Produto(s) não consta(m) em estoque e desejo cancelar a compra',
      'Comprei o(s) produto(s) errado(s) e desejo cancelar a compra',
      'Desisti da compra e desejo cancelá-la',
      'Produto estragado ou danificado e desejo cancelar a compra',
      'Produto(s) está(ão) vencido(s) e desejo cancelar a compra',
      'Outro problema com a minha compra',
    ]),
  });

  /*
  *Corpo da requisição pra solicitação de cancelamento de compra
  */
  export type SaleCancelRequest = Prettify<z.infer<typeof cancelRequestSchema>>;

  /*
   * Corpo de resposta da solicitação de cancelamento de compra
  */
  export type SaleCancelResponse = Prettify<{
    reason: string
    sale_id: number
    user_id: number
    sale_value: number
    shopmanager_id: number
    created_at: string
    updated_at: string
    id: number
  }>;

  /*
  * Check discount of coupon
  */
  export const GetDiscountCoupon = z.object({
    clientId: z.string(),
    storeId: z.string(),
    value: z.number(),
    code: z.string(),
    payment: z.enum(['credit_card', 'debit_card', 'pix']),
    cart: z.array(z.object({
      productId: z.number(),
      value: z.number(),
      categoryId: z.number(),
    })),
  });

  export interface CheckDiscountResponse {
    discount: number
  }

  export type ParametersToGetDiscountCoupon = z.infer<typeof GetDiscountCoupon>;

  /**
  * Schema para validar dados de entrada para envio de recibo por email
  */

  export interface SendReceiptResponse {
    ok: boolean
  }

  export const SendReceiptSchema = z.object({
    email: z.string().email(),
    saleId: z.number(),
  });

  export type SendReceiptRequest = Prettify<z.infer<typeof SendReceiptSchema>>;

  export type GetRequest = Prettify<number>;
  export type GetResponse = Prettify<OrdersDetails[]>;

  /*
  * Buscas vendas a partir de um totem
  */

  export const GetSalesByPWASchema = z.object({
    page: z.number().optional(),
    perPage: z.number().optional(),
    store: z.number().optional().nullable(),
    pwaId: z.string().optional(),
  });

  export type GetSalesByPWARequest = z.infer<typeof GetSalesByPWASchema>;

  export interface ListOrdersDetails {
    card_id: number | null;
    card_mask: string | null;
    client_id: number;
    coupon_discount: number | null;
    created_at: string;
    id: number;
    in_contingency: number;
    invoice_id: string;
    nsu: string;
    payment_method: 'credit_card' | 'pix';
    pwa_id: string;
    qrcode_url: string | null;
    status: InvoiceStatus;
    store: Store;
    store_id: number;
    total_price: number;
    type_gateway: string;
    updated_at: string;
  }

  export type OrdersResponse = Prettify<{
    data: ListOrdersDetails[];
    meta: Meta;
  }>;
}
