import { z } from 'zod';
import string from '../../../utils/string';

export namespace Address {
  /**
   * Esquema para atualizar dados da conta do usuário.
   */
  export const addressRequestSchema = z.object({
    city: z.string({ required_error: 'Cidade obrigatório' }).min(1, 'Cidade obrigatório'),
    complement: z.string().optional().nullable(),
    country: z.string(),
    district: z.string({ required_error: 'Bairro obrigatório' }).min(1, 'Bairro obrigatório'),
    number: z.string({ required_error: 'Número obrigatório' }).min(1, 'Número obrigatório'),
    state: z.string({ required_error: 'Estado obrigatório' }).max(2).min(2, { message: 'Obrigatório 2 caracteres' }),
    street: z.string({ required_error: 'Endereço obrigatório' }).min(1, 'Endereço obrigatório'),
    zipcode: z.string({ required_error: 'CEP obrigatório' })
      .min(8, { message: 'Obrigatório de 8 caracteres' })
      .transform(string.cleanNumber),
  });

  /**
   * Interface para representar um endereço.
   */
  export interface Address {
    street: string;
    state: string;
    district: string;
    zipcode: string;
    country: string;
    city: string;
    number: string;
    complement: string | undefined;
    created_at: string;
    updated_at: string;
  }

  export interface AddressResponse extends Address {
    id: number;
  }

  /**
   * Tipo para a requisição de criação de endereço.
   */
  export type CreateRequest = z.infer<typeof addressRequestSchema>;

  /**
   * Tipo para a requisição de atualização de endereço.
   */
  export type UpdateRequest = z.infer<typeof addressRequestSchema>;

  /**
   * Tipo para a resposta da criação de endereço.
   */
  export type CreateAddressResponse = AddressResponse;

  /**
   * Tipo para a resposta da atualização de endereço.
   */
  export type UpdateAddressResponse = AddressResponse;

}
