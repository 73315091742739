import { AxiosError } from 'axios';
import SdkError from '../../errors/SdkError';
import { HttpInstance } from '../../types';
import { Customer } from './customer.types';
import FeedbackModule from './feedback/feedback';
import AddressModule from './address/address';
import PaymentModule from './payment/payment';

export default (httpClient: HttpInstance) => ({
  /**
   * Feedback
   */
  feedback: FeedbackModule(httpClient),

  /**
   * Address
   */
  address: AddressModule(httpClient),

  /**
   * Payment
   */
  payment: PaymentModule(httpClient),

  /**
   * Consulta dados do usuário.
   * @returns Uma promessa que resolve com os dados do usuário consultado.
   * @throws {SdkError} - Se houver um erro HTTP.
   */
  read: async () => {
    const { data } = await httpClient.get<Customer.GetCustomerResponse>('/profile').catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /**
   * Atualiza dados do usuário.
   * @param args - Os argumentos necessários para atualizar os dados do usuário.
   * @returns Uma promessa que resolve com os dados do usuário atualizados.
   * @throws {SdkError} - Se houver um erro de validação ou HTTP.
   */
  update: async (args: Customer.UpdateRequestSchema) => {
    const body = await Customer.updateRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.put<Customer.UpdateCustomerResponse>('/profile', body).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },

  /**
   * Deleta dados do usuário.
   * @returns Uma promessa que resolve após a exclusão dos dados do usuário.
   * @throws {SdkError} - Se houver um erro HTTP.
   */
  delete: async () => {
    const response = await httpClient.delete<Customer.DeleteCustomerResponse>('/users/deleteMyself').catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return response;
  },

  /**
   * Atualiza avatar do usuário.
   * @param body - Os dados do formulário do avatar do usuário.
   * @returns Uma promessa que resolve com os dados do avatar atualizados.
   * @throws {SdkError} - Se houver um erro HTTP.
   */
  updateImage: async (body: Customer.RequestImageSchema) => {
    const { data } = await httpClient.put<Customer.UploadImageResponse>('/profile/image', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
});
