import { AxiosError } from 'axios';
import SdkError from '../../errors/SdkError';
import { HttpInstance } from '../../types';
import { Notification } from './notification.types';

export default (httpClient: HttpInstance) => ({
  /**
   * Listar notificações
   *
   * @param {Notification.SearchRequest} args Argumentos da busca
   */
  search: async (args: Notification.SearchRequest) => {
    const params = await Notification.searchRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.get<Notification.NotificationsResponse>('/profile/notifications', { params }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
   * Consulta uma notificação pelo Id
   * @param {number} id ID da compra
   */
  get: async (id: number) => {
    const { data } = await httpClient.get<Notification.NotificationDetails>(`/profile/notifications/${id}`);
    return data;
  },

  /**
   * Refresh token
   * @param tokenPush
   */
  refreshToken: async (tokenPush: string) => {
    const response = await httpClient.post<Promise<void>>('/push/update', { token: tokenPush }).catch((err) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return response;
  },
});
