export const CODES = {
  UNKNOW_ERROR: {
    message: {
      pt: 'Erro desconhecido',
      en: 'Unknow error',
    },
  },
  USER_ALREADY_EXISTS: {
    message: {
      pt: 'O usuário já existe',
      en: 'The user already exists',
    },
  },
  USER_NOT_FOUND: {
    message: {
      pt: 'Usuário não encontrado',
      en: 'User not found',
    },
  },
  UNAUTHORIZED: {
    message: {
      pt: 'Não autorizado',
      en: 'Unauthorized',
    },
  },
  INVALID_TOKEN: {
    message: {
      pt: 'Token inválido',
      en: 'Invalid token',
    },
  },
  EXPIRED_TOKEN: {
    message: {
      pt: 'Token expirado',
      en: 'Expired token',
    },
  },
  FORBIDDEN: {
    message: {
      pt: 'Proibido',
      en: 'Forbidden',
    },
  },
  INTERNAL_SERVER_ERROR: {
    message: {
      pt: 'Erro interno do servidor',
      en: 'Internal server error',
    },
  },
  NOT_FOUND: {
    message: {
      pt: 'Não encontrado',
      en: 'Not found',
    },
  },
  INVALID_CREDENTIALS: {
    message: {
      pt: 'Credenciais inválidas',
      en: 'Invalid credentials',
    },
  },
  INVALID_ID: {
    message: {
      pt: 'ID inválido',
      en: 'Invalid ID',
    },
  },
  INVALID_FIELD: {
    message: {
      pt: 'Campo inválido',
      en: 'Invalid field',
    },
  },
  INVALID_DATA: {
    message: {
      pt: 'Dados inválidos',
      en: 'Invalid data',
    },
  },
} as const;
