// implementation of an erro with a payload

import { AxiosError } from 'axios';
import { ZodError } from 'zod';

export default class SdkError<T = ZodError | AxiosError> extends Error {
  data: T;

  constructor(message: 'HTTP_ERROR' | 'VALIDATION_ERROR', body: T) {
    super(message);
    this.name = 'SdkError';
    this.data = body;

    Object.setPrototypeOf(this, SdkError.prototype);
  }
}
