import { z } from 'zod';

export namespace Advert {
  export interface RequestAllAdverts {
    storeId?: string | number | null | undefined;
    showIn: 'OUTSTORE' | 'INSIDESTORE' | 'SLEEPSCREEN';
  }

  export const requestAllAdvertsSchema = z.object({
    showIn: z.enum(['OUTSTORE', 'INSIDESTORE', 'SLEEPSCREEN']),
    storeId: z.union([z.string(), z.number()]).optional(),
  }).refine((data) => {
    if (data.showIn === 'OUTSTORE') {
      return true;
    }

    return data.storeId !== undefined;
  }, {
    message: 'storeId is required when showIn is not OUTSTORE',
    path: ['storeId'],
  });

  export interface Meta {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: any
  }

  export interface RequestOneAdverts {
    global: boolean
  }

  export interface AdvertItem {
    id: number
    name: string
    description: string
    image: string
    link: string
    status: boolean
    for_all_stores: boolean
    created_by_user_id: number
    isGlobal: boolean;
    priority: string
    created_at: string
    updated_at: string
    deleted_at: string
    show_in: string
    show_qr_code: boolean
  }

  type ShowInType = 'OUTSTORE' | 'SLEEPSCREEN';
  export interface RequestAdvertOutSideStore {
    showIn: ShowInType,
    page: number,
    perPage: number
  }

  export type AdvertResponse = AdvertItem;
  export type AdvertsResponse = AdvertItem[];
  export type AdvertOutSideStoreResponse = {
    meta: Meta
    data: AdvertItem[]
  };
}
