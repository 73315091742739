import { AxiosError } from 'axios';
import { SdkError } from '../../../errors';
import { HttpInstance } from '../../../types';
import { Advert } from './advert.types';

/**
 * Modulo de Anúncios da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Busca todos os anúncios de uma loja
   * @param params - Objeto onde contém como param o ID da loja
   * @returns Uma promessa que resolve com os dados dos anúncios da loja
   * @throws {SdkError} - Se houver um erro HTTP.
   */
  get: async (params: Advert.RequestAllAdverts) => {
    await Advert.requestAllAdvertsSchema.parseAsync(params).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const { data } = await httpClient.get<Advert.AdvertsResponse>('/global-advert/all', {
      params,
    }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
   * Busca um anúncio pelo ID
   * @param params - Objeto onde contém como param o `global: booleano`
   * @returns Uma promessa que resolve com os dados de um anúncio
   * @throws {SdkError} - Se houver um erro HTTP.
   */
  getOne: async (id: number, params: Advert.RequestOneAdverts) => {
    const { data } = await httpClient.get<Advert.AdvertResponse>(`/v2/advert/${id}`, {
      params,
    }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /*
   * Busca os anúncios classificados como fora da loja
   * @param params - Objeto onde contém como param o `global: booleano`
   * @returns Uma promessa que resolve com os dados de um anúncio
   * @throws {SdkError} - Se houver um erro HTTP.
  */
  getAdvertOutSideStore: async (params: Advert.RequestAdvertOutSideStore) => {
    const { data } = await httpClient.get<Advert.AdvertOutSideStoreResponse>('/global-advert', {
      params,
    }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
});
