import { AxiosError } from 'axios';
import { HttpInstance } from '../../types';
import { Check } from './check.types';
import SdkError from '../../errors/SdkError';

export default (httpClient: HttpInstance) => ({
  checkUnique: async (type: string, value: string) => {
    const { data } = await httpClient.get<Check.ResponseCheck>(`/v2/client/unique?type=${type}&value=${value}`)
      .catch((err: AxiosError) => {
        throw new SdkError('HTTP_ERROR', err);
      });

    return data;
  },
});
